<template>
  <main v-if="user" class="flex justify-center">
    <HeaderComponent />
    <section class="w-full lg:pl-[50px] lg:pt-[95px] pt-[95px] max-h-screen overflow-y-auto relative flex justify-start flex-col lg:p-[25px] p-[20px] h-auto min-h-screen bg-gray-200">
      <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[90%] max-w-[400px] flex flex-col">
          <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
              {{ errorMessage }}
          </div>
          <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
              {{ successMessage }}
          </div>
      </div>
      <div v-if="user.isSuper && isfiltre" class="flex flex-wrap mb-[20px]">
        <button @click="clearStockFilter" :class="!selectedStock ? 'bg-orange-600 text-white' : 'bg-gray-200 text-black border border-orange-600 rounded-[5px]'" class="p-[10px] rounded-[5px]">
          AT
        </button>
        <button @click="filterByStock('Stock Cotonou')" :class="selectedStock === 'Stock Cotonou' ? 'bg-orange-600 text-white' : 'bg-gray-200 text-black border border-orange-600 rounded-[5px]'" class="p-[10px] rounded-[5px] mx-[10px]">
          SC
        </button>
        <button @click="filterByStock('Stock Parakou')" :class="selectedStock === 'Stock Parakou' ? 'bg-orange-600 text-white' : 'bg-gray-200 text-black border border-orange-600 rounded-[5px]'" class="p-[10px] rounded-[5px] mx-[10px]">
          SP
        </button>
        <button @click="filterByStock('Magasin Cotonou')" :class="selectedStock === 'Magasin Cotonou' ? 'bg-orange-600 text-white' : 'bg-gray-200 text-black border border-orange-600 rounded-[5px]'" class="p-[10px] rounded-[5px] mx-[10px]">
          MC
        </button>
        <button @click="filterByStock('Magasin Parakou')" :class="selectedStock === 'Magasin Parakou' ? 'bg-orange-600 text-white' : 'bg-gray-200 text-black border border-orange-600 rounded-[5px]'" class="p-[10px] rounded-[5px] mx-[10px]">
          MP
        </button>
      </div>
      <div class="w-full flex flex-col lg:flex-row justify-center items-center relative">
        <div class="flex justify-start items-center relative w-full bg-white rounded-[10px] min-h-[400px] flex-col">
          <div class="flex w-full justify-between items-center p-[10px] md:p-[20px]">
            <div class="flex justify-start  max-w-[350px] items-center w-full relative">
              <label for=""><i class="fa-solid fa-search text-sm font-bold text-gray-700 absolute right-[10px] top-[10px]"></i></label>
              <input type="text" name="" v-model="searchQuery" @input="searchProduit" id="" placeholder="Recherchez un produit" class="w-full font-semibold max-w-[350px] rounded-[5px] border border-gray-400 outline-none p-[10px] text-sm">
            </div>
            <div class="flex justify-center ml-[10px] md:ml-0 items-center">
              <button @click="ajouterArticle()" class="md:p-[10px] p-[8px] bg-green-600/10 text-green-600 md:text-sm text-xs md:font-bold font-semibold text-center border border-green-600 rounded-[5px] md:mx-[20px] mx-[10px] flex justify-center items-center">
                <i class="fa-solid fa-add text-xs font-bold md:mr-[5px]"></i>
                <span class="hidden md:flex">Ajouter</span>
              </button>
              <button @click="toggleFiltre()" class="md:p-[10px] p-[8px] bg-gray-600/10 text-gray-600 md:text-sm text-xs md:font-bold font-semibold text-center border border-gray-600 rounded-[5px] flex justify-center items-center">
                <i class="fa-solid fa-filter text-xs font-bold md:mr-[5px]"></i>
                <span class="hidden md:flex">Filtrer</span>
              </button>
            </div>
          </div>
          <ul class="flex w-full bg-gray-200 relative flex-col">
            <!--
            <li class="flex w-full justify-between items-center p-[10px] bg-gray-200 md:p-[20px]">
              <div class="text-center md:flex hidden justify-center items-center text-sm font-bold text-gray-800 w-[50px]">Id</div>
              <div class="text-center flex justify-center items-center text-sm font-bold text-gray-800 md:w-[50px] w-[20px]"><input type="checkbox" name="" id=""></div>
              <div class="text-center flex justify-center items-center text-sm font-bold text-gray-800 min-w-[80px] w-[15%]">Image</div>
              <div class="text-left flex justify-start items-center text-sm font-bold text-gray-800 w-[calc(75%-70px)]">Nom</div>
              <div class="text-center flex justify-center items-center text-sm font-bold text-gray-800 w-[50px] ">Qt</div>
              <div class="flex justify-start items-center p-[5px] text-sm font-bold text-gray-800 w-[50px]">Sta</div>
              <div v-if="user.isSuper" class="text-center flex justify-center items-center text-sm font-bold text-gray-800 w-[40px] min-w-[40px]">Act</div>
            </li>
            -->
            <li id="li_tw" v-for="(lot, lotIndex) in paginatedLots" :key="lotIndex" :index="lotIndex" class="flex flex-col rounded-[10px] w-full bg-white relative shadow-9xl p-[10px] md:px-[20px] my-[10px]">
              <div @click="toggleLot(lotIndex)" class="flex justify-between items-center cursor-pointer">
                <div class="text-center flex justify-center items-center text-sm font-bold text-gray-800 md:min-w-[80px] min-w-[40px] w-[40px] md:w-[15%] h-[40px] relative">
                  <img :src="lot.photos[0]" :alt="lot.name" class="h-full object-contain absolute">
                </div>
                <div class="text-left flex justify-start items-start text-sm font-semibold flex-col md:w-[calc(75%-100px)] w-[calc(75%-70px)]">
                  <h1 class="text-sm font-bold text-gray-800">{{ lot.name }}</h1>
                </div>
                <!--
                <div class="text-center md:flex hidden justify-center items-center text-sm text-gray-800">
                 <span v-if="lot.produits.length == 0 ">0 Produit</span> <span v-else>{{ lot.produits.length }} Produits</span>
                </div>   
                -->
                <div class="flex justify-start items-center p-[5px] text-sm font-semibold text-gray-700 md:w-[55px]">
                  <span class="p-[5px] rounded-[5px]" :class="{'bg-green-600 text-white': invalidCount(lot) === 0, 'bg-blue-600 text-white': invalidCount(lot) > 0}">
                    <span v-if="invalidCount(lot) === 0">Suc</span>
                    <span v-else>Pen {{ invalidCount(lot) }}</span>
                  </span>
                </div>
                <div class="text-center relative flex justify-center items-center text-sm font-bold text-gray-800 w-[40px] min-w-[40px]">
                  <button class="p-[5px] bg-gray-400 w-[30px] h-[30px] rounded-[50%] text-sm text-white">
                    <i v-if="activeLotIndex === lotIndex" class="fa-solid fa-chevron-up duration-200"></i>
                    <i v-else class="fa-solid fa-chevron-down duration-200"></i>
                  </button>
                </div>
              </div>
            
              <!-- Liste des produits (accordéon) -->
              <ul v-if="activeLotIndex === lotIndex" class="mt-[10px]">
                <li v-for="(produit, produitIndex) in lot.produits" :key="produitIndex" :index="produitIndex" :class="{'hidden': produit.quantite <= 0}" class="flex w-full bg-gray-100 shadow-9xl justify-between relative items-center p-[10px] md:px-[20px] my-[5px]">
                  <div class="text-center md:flex hidden justify-center items-center text-sm font-bold text-gray-800 w-[50px]">#{{ produitIndex + 1 }}</div>
                  <div class="text-center flex justify-center items-center text-sm font-bold text-gray-800 md:w-[50px] w-[20px]">
                    <input type="checkbox" name="" id="">
                  </div>
                  <!--
                  <div class="text-center flex justify-center items-center text-sm font-bold text-gray-800 min-w-[80px] w-[15%] h-[40px] relative">
                    <img :src="lot.photos[0]" :alt="lot.name" class="h-full object-contain absolute">
                  </div>
                  -->
                  <div class="text-left flex ml-[5px] justify-start items-start text-sm font-semibold flex-col w-[calc(75%-70px)]">
                    <span class="text-xs text-gray-400 font-semibold">
                      <span class="text-gray-500" v-if="produit.prix_reduit !== produit.prix">{{ produit.prix_reduit }} FCFA - </span>
                      <span :class="{'line-through': produit.prix_reduit !== produit.prix, 'text-gray-500': produit.prix_reduit === produit.prix}">{{ produit.prix }} FCFA </span>
                      <span class="ml-[5px]">{{ produit.stock }}</span>
                    </span>
                    <h1 v-if="produit.capacities && produit.colors && produit.etat" class="text-xs font-bold text-gray-800"><span v-if="produit.capacities[0]">{{ produit.capacities[0] }} -</span> <span v-if="produit.colors[0]">{{ produit.colors[0] }} </span></h1> 
                  </div>
                  <div class="text-center flex justify-center items-center text-sm text-gray-800 w-[50px]">{{ produit.quantite }}</div>
                  <div class="flex justify-start items-center p-[5px] text-sm font-semibold text-gray-700 md:w-[55px]">
                    <span class="p-[5px] rounded-[5px]" :class="{'bg-green-600 text-white': produit.isValide , 'bg-blue-600 text-white': !produit.isValide}">
                      <span v-if="produit.isValide">Suc</span><span v-else>Pen</span>
                    </span>
                  </div>
                  <div v-if="user.isSuper || !produit.isValide" class="text-center relative flex justify-center items-center text-sm font-bold text-gray-800 w-[40px] min-w-[40px]">
                    <button @click="toggleBox(produit._id)" class="p-[5px] bg-gray-400 w-[30px] h-[30px] rounded-[50%] text-sm text-white">
                      <i v-if="showBoxId === produit._id" class="fa-solid fa-times cursor-pointer"></i>
                      <i v-else class="fa-solid fa-ellipsis-vertical cursor-pointer"></i>
                    </button>
                  </div>
                  <div v-if="showBoxId === produit._id" class="w-[100px] h-auto bg-white absolute text-xs font-bold right-[5px] top-[50px] z-30 shadow-4xl p-[10px] rounded-[5px] flex flex-col items-start">
                    <div @click="ouvrirModification(lot, produit)" class="w-full cursor-pointer h-[25px] border border-blue-600 rounded-[5px] flex justify-center items-center bg-blue-600/10 text-sm my-[5px] text-blue-600">
                      <i class="fa-solid fa-pen"></i>
                    </div>
                    <div @click="toggleSupBox(produit._id)" class="w-full cursor-pointer h-[25px] border border-red-600 rounded-[5px] flex justify-center items-center bg-red-600/10 text-sm my-[5px] text-red-600">
                      <i class="fa-solid fa-trash"></i>
                    </div>
                    <div v-if="user.isSuper" @click="toggleValide(lot._id, produit._id)" class="w-full my-[5px]">
                      <button v-if="produit.isValide" class="bg-red-600 text-white w-full p-[5px] rounded-[5px]">Rejecter</button>
                      <button v-else class="bg-green-600 text-white w-full p-[5px] rounded-[5px]">Valider</button>
                    </div>
                    <div v-if="user.isSuper" @click="toggleOnline(lot._id, produit._id)" class="w-full my-[5px]">
                      <button v-if="produit.isOnline" class="bg-red-600 text-white w-full p-[5px] rounded-[5px]">Hors ligne</button>
                      <button v-else class="bg-green-600 text-white w-full p-[5px] rounded-[5px]">En ligne</button>
                    </div>
                  </div>
                  <div v-if="showSupId === produit._id" class="w-full h-full bg-gray-800/50 fixed top-0 right-0 flex justify-center items-center lg:w-[calc(100vw-280px)] z-[35]">
                    <div class="flex justify-center relative items-center flex-col p-[15px] bg-white rounded-[10px] w-[85%] max-w-[400px] min-h-[100px]">
                      <h1 class="text-center text-sm md:text-xl font-semibold text-gray-800">
                        Voulez vous vraiment supprimer l'article "{{ lot.name }} ( {{produit.prix_reduit}} FCFA - {{produit.quantite}} Unité )"
                      </h1>
                      <div class="w-full relative flex justify-between items-center mt-[25px]">
                        <div @click="toggleSupBox(produit._id)" class="w-[48%] cursor-pointer p-[10px] border border-gray-600 rounded-[5px] flex justify-center items-center bg-gray-600/10 text-sm font-bold my-[5px] text-gray-600">
                          Annuler
                        </div>
                        <div @click="deleteProduit(lot._id, produit._id)" class="w-[48%] cursor-pointer p-[10px] border border-red-600 rounded-[5px] flex justify-center items-center bg-red-600/10 text-sm font-bold my-[5px] text-red-600">
                          Supprimer
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <button v-if="user.isSuper || invalidCount(lot) !== 0" @click="ouvrirModificationLot(lot)" class="rounded-[50%] text-[10px] font-bold w-[25px] h-[25px] absolute top-[-8px] right-[-8px] flex justify-center items-center border border-gray-300 text-blue-600 bg-gray-200" ><i class="fa-solid fa-pen"></i></button>
            </li>
            <div v-if="paginatedLots.length == 0 && !isLoading1" class="flex w-full h-[200px] relative text-center justify-center items-center text-sm font-bold text-gray-800">
              Aucun produit disponible
            </div>
            <div v-if="isLoading1" class="w-full min-h-[200px] flex justify-center items-center">
                <div class="spinner22"></div>
            </div>
            <div class="md:py-[50px] py-[25px] flex w-full md:justify-end flex-wrap justify-center items-center px-[20px]">
              <div v-for="page in totalPages" :key="page" @click="goToPage(page)" 
                :class="{'bg-orange-600 text-white border border-orange-600': currentPage === page, 'bg-white': currentPage !== page}" 
                class="w-[30px] h-[30px] min-w-[30px] min-h-[30px] m-[5px] rounded-[5px] shadow-4xl cursor-pointer border flex justify-center items-center font-bold text-sm text-center">
                {{ page }}
              </div>
            </div>
            <!---
            <div class="md:py-[50px] py-[25px] flex w-full md:justify-end justify-center items-center px-[20px]">
              <div class="w-[30px] h-[30px] mx-[5px] border text-white bg-orange-600 border-orange-500 flex justify-center items-center font-bold text-sm text-center">1</div>
              <div class="w-[30px] h-[30px] mx-[5px] cursor-pointer border border-gray-500 flex justify-center items-center font-bold text-sm text-center">2</div>
              <div class="w-[30px] h-[30px] mx-[5px] cursor-pointer border border-gray-500 flex justify-center items-center font-bold text-sm text-center">3</div>
              <div class="w-[30px] h-[30px] mx-[5px] cursor-pointer border border-gray-500 flex justify-center items-center font-bold text-sm text-center">...</div>
              <div class="w-[30px] h-[30px] mx-[5px] cursor-pointer border bg-orange-500 text-white border-orange-500 flex justify-center items-center font-bold text-sm text-center"><i class="fa-solid fa-chevron-right"></i></div>
            </div>
            -->
          </ul>
        </div>
      </div>
      <div id="parent" v-if="modifieArticle" class="min-h-[200px] h-auto md:max-w-[450px] w-[calc(100%-40px)] shadow-10xl z-[55] overflow-y-auto fixed p-[10px] flex justify-start items-center flex-col right-[20px]  bottom-[20px] top-[90px] rounded-[10px] bg-white">
        <div class="flex flex-col sticky bg-white top-[-10px] z-50 w-full justify-center items-center md:px-[20px] py-[15px]">
          <div class="flex w-full relative justify-between items-center ">
            <div class="flex items-center">
              <button v-if="user.isSuper" @click="deleteLot(ModifielotId)" class="rounded-[50%] text-[10px] font-bold h-[35px] w-[35px] mr-[10px] flex justify-center items-center border border-gray-300 text-red-600 bg-gray-200" ><i class="fa-solid fa-trash"></i></button>
              <h1 class="text-sm font-bold text-gray-800">Modifier un article</h1>
            </div>
            <div class="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-gray-100" @click="fermerModification()">
              <i class="fa-solid fa-times cursor-pointer"></i>
            </div>
          </div>
        </div>
        <form v-if="ModifiecreerLot" @submit.prevent="ModifieLot()" class="mb-[10px] md:px-[20px] relative w-full">
          <div class="w-full flex pt-[20px] justify-center items-center flex-col relative">
            <div
              v-if="!selectedImages.length"
              @click="triggerFileInput"
              class="w-full h-[150px] border border-dashed cursor-pointer border-gray-400 rounded-[10px] flex flex-col justify-center items-center"
            >
              <i class="fa-solid fa-camera text-gray-400 text-4xl"></i>
              <h1 class="text-sm font-bold text-gray-400 text-center mt-[5px]">
                Ajouter des images <span class="text-red-500">*</span>
              </h1>
            </div>
            <div v-else class="w-full h-[150px] border border-dashed border-gray-400 rounded-[10px] flex flex-col justify-center items-center relative">
              <img :src="selectedImages[0]" class="h-full w-full object-cover rounded-[10px]" />
              <div @click="removeImage(0)" class="absolute top-[5px] right-[5px] w-[20px] h-[20px] flex justify-center items-center bg-red-600 text-white rounded-full cursor-pointer">
                <i class="fa-solid fa-times"></i>
              </div>
            </div>
            <input type="file" ref="fileInput" @change="handleFileChange" multiple class="hidden">
            <div id="parent" class="w-full py-[10px] rounded-[10px] pl-[2px] flex justify-start items-center overflow-x-auto">
              <div
                v-for="(image, index) in selectedImages"
                :key="index"
                class="relative w-[80px] h-[80px] min-w-[80px] mr-[15px] min-h-[80px] cursor-pointer rounded-[10px] shadow-9xl flex justify-center items-center"
              >
                <img :src="image" class="h-full w-full object-cover rounded-[10px]" />
                <div @click="removeImage(index)" class="absolute top-[5px] right-[5px] w-[20px] h-[20px] flex justify-center items-center bg-red-600 text-white rounded-full cursor-pointer">
                  <i class="fa-solid fa-times"></i>
                </div>
              </div>
              <div @click="triggerFileInput" class="w-[80px] h-[80px] min-w-[80px] mr-[15px] min-h-[80px] cursor-pointer rounded-[10px] shadow-9xl flex justify-center items-center">
                <i class="fa-solid fa-add text-3xl text-gray-600"></i>
              </div>
            </div>
            <div class="w-full relative">
              <label for="" class="text-sm font-bold">Choisissez une catégorie <span class="text-red-500">*</span></label>
              <div class="w-full flex justify-between items-center relative py-[10px]">
                <div v-for="(category, index) in categories" :key="index" class="relative w-[calc(100%/3-10px)] h-[100px] rounded-[5px] p-[10px] cursor-pointer flex justify-center items-center "
                  :class="{'shadow-4xl': selectedCategory !== index, 'shadow-4xl shadow-blue-500': selectedCategory === index}">
                  <input type="radio" :id="'category-' + index" name="categorie" :value="category.name" v-model="ModifieLotCategorie" class="absolute top-[5px] right-[5px] m-[5px]">
                  <div class="flex flex-col justify-center items-center text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" :d="category.icone"/></svg>
                    <label :for="'category-' + index" class="w-full h-full text-sm font-bold flex cursor-pointer justify-center items-center">{{ category.name }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Nom du Lot <span class="text-red-500">*</span></label>
              <input type="text" v-model="ModifienomLot" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Description de l'article</label>
              <textarea type="text" v-model="Modifiedescription" required class="p-[10px] h-[100px] resize-none w-full rounded-[5px] outline-none border border-gray-400"></textarea>
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Seuil <span class="text-red-500">*</span></label>
              <input type="text" v-model="Modifieseuil" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <button type="submit" class="p-[10px] px-[20px] text-sm w-full flex justify-center items-center h-[45px] font-bold bg-gray-800 text-white rounded-[5px]">
                
                <span v-if="!isLoading ">Modifier</span>
                <div v-if="isLoading" class="spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
            </div>
          </div>
        
        </form>
        <form v-if="ModifieuseLot" @submit.prevent="submitModification()" class="my-[15px] mb-[5px] md:px-[20px] relative w-full">
          <div class="w-full flex pt-[20px] justify-center items-center flex-col relative">
            
            <!-- Section pour ajouter la capacité des téléphones -->
            <div v-if="ModifieLotCategorie == 'Apple' || ModifieLotCategorie == 'Samsung'" class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Capacité du téléphone</label>
              <div class="flex">
                <input type="text" v-model="ModifienewCapacity" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" placeholder="Entrez la capacité">
                <button @click="ModifieaddCapacity" type="button" class="ml-[10px] p-[10px] px-[20px] text-sm font-bold bg-blue-500 text-white rounded-[5px]">Ajouter</button>
              </div>
              <div class="flex flex-wrap mt-[10px]">
                <button v-for="(capacity, index) in Modifiecapacities" :key="index" @click="ModifieremoveCapacity(index)" class="m-[5px] p-[5px] bg-gray-300 text-gray-800 rounded-[5px]">
                  {{ capacity }} <i class="fa-solid fa-times ml-[5px]"></i>
                </button>
              </div>
            </div>

            <!-- Section pour ajouter la couleur du téléphone -->
            <div v-if="ModifieLotCategorie == 'Apple' || ModifieLotCategorie == 'Samsung'" class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Couleur du téléphone</label>
              <div class="flex">
                <input type="text" v-model="ModifienewColor" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" placeholder="Entrez la couleur">
                <button @click="ModifieaddColor" type="button" class="ml-[10px] p-[10px] px-[20px] text-sm font-bold bg-blue-500 text-white rounded-[5px]">Ajouter</button>
              </div>
              <div class="flex flex-wrap mt-[10px]">
                <button v-for="(color, index) in Modifiecolors" :key="index" @click="ModifieremoveColor(index)" class="m-[5px] p-[5px] bg-gray-300 text-gray-800 rounded-[5px]">
                  {{ color }} <i class="fa-solid fa-times ml-[5px]"></i>
                </button>
              </div>
            </div>
            <div v-if="ModifieLotCategorie == 'Apple' || ModifieLotCategorie == 'Samsung'" class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">IMEI 1 <span class="text-red-500">*</span></label>
              <input type="text" v-model="Modifieimei1" :required="ModifieLotCategorie == 'Apple' || ModifieLotCategorie == 'Samsung'" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div v-if="ModifieLotCategorie == 'Apple' || ModifieLotCategorie == 'Samsung'" class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">IMEI 2 <span class="text-red-500">*</span></label>
              <input type="text" v-model="Modifieimei2" :required="ModifieLotCategorie == 'Apple' || ModifieLotCategorie == 'Samsung'" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Stock <span class="text-red-500">*</span></label>
              <select name="stock" id="" v-model="Modifiestock" required class="p-[10px] w-full rounded-[5px] outline-none border text-sm font-semibold text-gray-600 border-gray-400">
                  <!-- Si l'utilisateur est Super, afficher toutes les options -->
                <option v-if="user.isSuper" value="Stock Parakou">Stock Parakou</option>
                <option v-if="user.isSuper" value="Stock Cotonou">Stock Cotonou</option>
                <option v-if="user.isSuper" value="Magasin Parakou">Magasin Parakou</option>
                <option v-if="user.isSuper" value="Magasin Cotonou">Magasin Cotonou</option>

                <!-- Sinon, afficher seulement l'option correspondant à la boutique de l'utilisateur -->
                <option v-else-if="user.boutique == 'Parakou'" value="Stock Parakou">Stock Parakou</option>
                <option v-else-if="user.boutique == 'Cotonou'" value="Stock Cotonou">Stock Cotonou</option>
              </select>
            </div>
            <div class="flex w-full relative justify-between items-center flex-row ">
              <div class="my-[10px] w-full relative flex flex-col">
                <label for="" class="text-sm font-bold">Quantité <span class="text-red-500">*</span></label>
                <input v-model="Modifiequantite" type="text" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" required>
              </div>
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Prix <span class="text-red-500">*</span></label>
              <input type="text" v-model.number="Modifieprix" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
              <span class="absolute right-[10px] text-sm font-bold top-[30px]"> FCFA</span>
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Appliquer une réduction</label>
              <input type="text" v-model.number="Modifiereduction" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div v-if="Modifieprix && Modifiereduction" class="w-full flex flex-col relative my-[10px]">
              <label class="text-sm font-bold">Pourcentage de réduction</label> 
              <span class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">{{ ModifiepourcentageReduction }}%</span>
            </div>
            <div v-if="Modifieprix && Modifiereduction" class="w-full flex flex-col relative my-[10px]">
              <label class="text-sm font-bold">Montant restant</label>
              <span class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">{{ ModifiemontantRestant }} FCFA</span>
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <button type="submit" class="p-[10px] px-[20px] text-sm w-full flex justify-center items-center h-[45px] font-bold bg-gray-800 text-white rounded-[5px]">
                
                <span v-if="!isLoading ">Modifier</span>
                <div v-if="isLoading" class="spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div id="parent" v-if="ajouteArticle" class="min-h-[200px] h-auto md:max-w-[450px] w-[calc(100%-40px)] shadow-10xl z-[55] overflow-y-auto fixed p-[10px] flex justify-start items-center flex-col right-[20px] bottom-[20px] top-[90px] rounded-[10px] bg-white">
        <div class="flex flex-col sticky bg-white top-[-10px] z-50 w-full justify-center items-center md:px-[20px] py-[15px]">
          <div class="flex w-full relative justify-between items-center ">
            <h1 class="text-sm font-bold text-gray-800">Ajouter un article</h1>
            <div class="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-gray-100" @click="fermerAjout()">
              <i class="fa-solid fa-times cursor-pointer"></i>
            </div>
          </div>
          <div class="flex w-full relative justify-between py-[10px] md:justify-start items-center">
            <button @click="toggleuseLot()" class="p-[10px] rounded-[5px] text-sm font-bold md:w-auto w-[48%]" :class="{'border border-blue-600 bg-transparent text-blue-600': !useLot, 'bg-blue-600 text-white border border-blue-600': useLot}">Ajouter à un lot</button>
            <button @click="togglecreerLot()" class="p-[10px] rounded-[5px] text-sm font-bold md:ml-[20px] md:w-auto w-[48%]" :class="{'border border-green-600 bg-transparent text-green-600': !creerLot, 'bg-green-600 text-white border borer-green-600': creerLot}">Créer un lot</button>
          </div>
        </div>
        <form v-if="creerLot" @submit.prevent="createLot()" class="mb-[10px] md:px-[20px] relative w-full">
          <div class="w-full flex pt-[20px] justify-center items-center flex-col relative">
            <div
              v-if="!selectedImages.length"
              @click="triggerFileInput"
              class="w-full h-[150px] border border-dashed cursor-pointer border-gray-400 rounded-[10px] flex flex-col justify-center items-center"
            >
              <i class="fa-solid fa-camera text-gray-400 text-4xl"></i>
              <h1 class="text-sm font-bold text-gray-400 text-center mt-[5px]">
                Ajouter des images <span class="text-red-500">*</span>
              </h1>
            </div>
            <div v-else class="w-full h-[150px] border border-dashed border-gray-400 rounded-[10px] flex flex-col justify-center items-center relative">
              <img :src="selectedImages[0]" class="h-full w-full object-cover rounded-[10px]" />
              <div @click="removeImage(0)" class="absolute top-[5px] right-[5px] w-[20px] h-[20px] flex justify-center items-center bg-red-600 text-white rounded-full cursor-pointer">
                <i class="fa-solid fa-times"></i>
              </div>
            </div>
            <input type="file" ref="fileInput" required @change="handleFileChange" multiple class="hidden">
            <div id="parent" class="w-full py-[10px] rounded-[10px] pl-[2px] flex justify-start items-center overflow-x-auto">
              <div
                v-for="(image, index) in selectedImages"
                :key="index"
                class="relative w-[80px] h-[80px] min-w-[80px] mr-[15px] min-h-[80px] cursor-pointer rounded-[10px] shadow-9xl flex justify-center items-center"
              >
                <img :src="image" class="h-full w-full object-cover rounded-[10px]" />
                <div @click="removeImage(index)" class="absolute top-[5px] right-[5px] w-[20px] h-[20px] flex justify-center items-center bg-red-600 text-white rounded-full cursor-pointer">
                  <i class="fa-solid fa-times"></i>
                </div>
              </div>
              <div @click="triggerFileInput" class="w-[80px] h-[80px] min-w-[80px] mr-[15px] min-h-[80px] cursor-pointer rounded-[10px] shadow-9xl flex justify-center items-center">
                <i class="fa-solid fa-add text-3xl text-gray-600"></i>
              </div>
            </div>
            <div class="w-full relative">
              <label for="" class="text-sm font-bold">Choisissez une catégorie <span class="text-red-500">*</span></label>
              <div class="w-full flex justify-between items-center relative py-[10px]">
                <div v-for="(category, index) in categories" :key="index" class="relative w-[calc(100%/3-10px)] h-[100px] rounded-[5px] p-[10px] cursor-pointer flex justify-center items-center "
                  :class="{'shadow-4xl': selectedCategory !== index, 'shadow-4xl shadow-blue-500': selectedCategory === index}">
                  <input type="radio" :id="'category-' + index" name="categorie" :value="category.name" v-model="categori" class="absolute top-[5px] right-[5px] m-[5px]">
                  <div class="flex flex-col justify-center items-center text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" :d="category.icone"/></svg>
                    <label :for="'category-' + index" class="w-full h-full text-sm font-bold flex cursor-pointer justify-center items-center">{{ category.name }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Nom du Lot <span class="text-red-500">*</span></label>
              <input type="text" v-model="nomLot" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Description de l'article</label>
              <textarea type="text" v-model="description" required class="p-[10px] h-[100px] resize-none w-full rounded-[5px] outline-none border border-gray-400"></textarea>
            </div>
            <div class="w-full hidden flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Etat <span class="text-red-500">*</span></label>
              <select name="etat" id="" v-model="etat" :required="categories == 'Apple' || categories == 'Samsung'" class="p-[10px] w-full rounded-[5px] outline-none border text-sm font-semibold text-gray-600 border-gray-400">
                <option value="Scellé">Scellé</option>
                <option value="Venu">Venu</option>
                <option v-if="user.isSuper" value="Occasion">Occasion</option>
              </select>
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Seuil <span class="text-red-500">*</span></label>
              <input type="text" v-model="seuil" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <button type="submit" class="p-[10px] px-[20px] w-full flex justify-center items-center h-[45px] text-sm font-bold bg-gray-800 text-white rounded-[5px]">
                
                <span v-if="!isLoading ">Créer</span>
                <div v-if="isLoading" class="spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
            </div>
          </div>
        </form>
        <form v-if="useLot" @submit.prevent="submitForm()" class="mb-[10px] md:px-[20px] relative w-full">
          <div class="w-full flex pt-[10px] justify-center items-center flex-col relative">
            <!-- creerLot
            <div
              v-if="!selectedImages.length"
              @click="triggerFileInput"
              class="w-full h-[150px] border border-dashed cursor-pointer border-gray-400 rounded-[10px] flex flex-col justify-center items-center"
            >
              <i class="fa-solid fa-camera text-gray-400 text-4xl"></i>
              <h1 class="text-sm font-bold text-gray-400 text-center mt-[5px]">
                Ajouter des images <span class="text-red-500">*</span>
              </h1>
            </div>
            <div v-else class="w-full h-[150px] border border-dashed border-gray-400 rounded-[10px] flex flex-col justify-center items-center relative">
              <img :src="selectedImages[0]" class="h-full w-full object-cover rounded-[10px]" />
              <div @click="removeImage(0)" class="absolute top-[5px] right-[5px] w-[20px] h-[20px] flex justify-center items-center bg-red-600 text-white rounded-full cursor-pointer">
                <i class="fa-solid fa-times"></i>
              </div>
            </div>
            <input type="file" ref="fileInput" required @change="handleFileChange" multiple class="hidden">
            <div id="parent" class="w-full py-[10px] rounded-[10px] pl-[2px] flex justify-start items-center overflow-x-auto">
              <div
                v-for="(image, index) in selectedImages"
                :key="index"
                class="relative w-[80px] h-[80px] min-w-[80px] mr-[15px] min-h-[80px] cursor-pointer rounded-[10px] shadow-9xl flex justify-center items-center"
              >
                <img :src="image" class="h-full w-full object-cover rounded-[10px]" />
                <div @click="removeImage(index)" class="absolute top-[5px] right-[5px] w-[20px] h-[20px] flex justify-center items-center bg-red-600 text-white rounded-full cursor-pointer">
                  <i class="fa-solid fa-times"></i>
                </div>
              </div>
              <div @click="triggerFileInput" class="w-[80px] h-[80px] min-w-[80px] mr-[15px] min-h-[80px] cursor-pointer rounded-[10px] shadow-9xl flex justify-center items-center">
                <i class="fa-solid fa-add text-3xl text-gray-600"></i>
              </div>
            </div>
            <div class="w-full relative">
              <label for="" class="text-sm font-bold">Choisissez une catégorie <span class="text-red-500">*</span></label>
              <div class="w-full flex justify-between items-center relative py-[10px]">
                <div v-for="(category, index) in categories" :key="index" class="relative w-[calc(100%/3-10px)] h-[100px] rounded-[5px] p-[10px] cursor-pointer flex justify-center items-center "
                  :class="{'shadow-4xl': selectedCategory !== index, 'shadow-4xl shadow-blue-500': selectedCategory === index}">
                  <input type="radio" :id="'category-' + index" name="categorie" :value="category.name" v-model="categori" class="absolute top-[5px] right-[5px] m-[5px]">
                  <div class="flex flex-col justify-center items-center text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" :d="category.icone"/></svg>
                    <label :for="'category-' + index" class="w-full h-full text-sm font-bold flex cursor-pointer justify-center items-center">{{ category.name }}</label>
                  </div>
                </div>
              </div>
            </div>
            -->
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Nom Lot <span class="text-red-500">*</span></label>
              <input type="text" @input="suggestions('nomuseLot')" v-model="nomuseLot" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
              <ul v-if="suggestionsLot.length" class="absolute z-50 bg-white border mt-[60px] w-full max-h-[200px] overflow-y-auto">
                <li v-for="(lot, index) in suggestionsLot" :key="index" @click="selectLot(lot)" class="p-2 cursor-pointer hover:bg-gray-200">
                  {{ lot.name }}
                </li>
              </ul>
            </div>
            <!---
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Nom de l'article <span class="text-red-500">*</span></label>
              <input type="text" v-model="nom" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            -->
            <!-- Section pour ajouter la capacité des téléphones -->
            <div v-if="selectedLotCategori == 'Apple' || selectedLotCategori == 'Samsung'" class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Capacité du téléphone</label>
              <div class="flex">
                <input type="text" v-model="newCapacity" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" placeholder="Entrez la capacité">
                <button @click="addCapacity" type="button" class="ml-[10px] p-[10px] px-[20px] text-sm font-bold bg-blue-500 text-white rounded-[5px]">Ajouter</button>
              </div>
              <div class="flex flex-wrap mt-[10px]">
                <button v-for="(capacity, index) in capacities" :key="index" @click="removeCapacity(index)" class="m-[5px] p-[5px] bg-gray-300 text-gray-800 rounded-[5px]">
                  {{ capacity }} <i class="fa-solid fa-times ml-[5px]"></i>
                </button>
              </div>
            </div>
          
            <!-- Section pour ajouter la couleur du téléphone -->
            <div v-if="selectedLotCategori == 'Apple' || selectedLotCategori == 'Samsung'" class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Couleur du téléphone</label>
              <div class="flex">
                <input type="text" v-model="newColor" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400" placeholder="Entrez la couleur">
                <button @click="addColor" type="button" class="ml-[10px] p-[10px] px-[20px] text-sm font-bold bg-blue-500 text-white rounded-[5px]">Ajouter</button>
              </div>
              <div class="flex flex-wrap mt-[10px]">
                <button v-for="(color, index) in colors" :key="index" @click="removeColor(index)" class="m-[5px] p-[5px] bg-gray-300 text-gray-800 rounded-[5px]">
                  {{ color }} <i class="fa-solid fa-times ml-[5px]"></i>
                </button>
              </div>
            </div>
            <div v-if="selectedLotCategori == 'Apple' || selectedLotCategori == 'Samsung'" class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">IMEI 1 <span class="text-red-500">*</span></label>
              <input type="text" v-model="imei1" :required="selectedLotCategori == 'Apple' || selectedLotCategori == 'Samsung'" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div v-if="selectedLotCategori == 'Apple' || selectedLotCategori == 'Samsung'" class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">IMEI 2 <span class="text-red-500">*</span></label>
              <input type="text" v-model="imei2" :required="selectedLotCategori == 'Apple' || selectedLotCategori == 'Samsung'" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div v-if="selectedLotCategori != 'Apple' || selectedLotCategori != 'Samsung'" class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Quantité <span class="text-red-500">*</span></label>
              <input type="text" v-model="quantite" :required="selectedLotCategori !== 'Apple' || selectedLotCategori !== 'Samsung'" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Stock <span class="text-red-500">*</span></label>
              <select name="stock" id="" v-model="stock" required class="p-[10px] w-full rounded-[5px] outline-none border text-sm font-semibold text-gray-600 border-gray-400">
                <!-- Si l'utilisateur est Super, afficher toutes les options -->
                <option v-if="user.isSuper" value="Stock Parakou">Stock Parakou</option>
                <option v-if="user.isSuper" value="Stock Cotonou">Stock Cotonou</option>
                <option v-if="user.isSuper" value="Magasin Parakou">Magasin Parakou</option>
                <option v-if="user.isSuper" value="Magasin Cotonou">Magasin Cotonou</option>

                <!-- Sinon, afficher seulement l'option correspondant à la boutique de l'utilisateur -->
                <option v-else-if="user.boutique == 'Parakou'" value="Stock Parakou">Stock Parakou</option>
                <option v-else-if="user.boutique == 'Cotonou'" value="Stock Cotonou">Stock Cotonou</option>
              </select>
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Prix <span class="text-red-500">*</span></label>
              <input type="text" v-model.number="prix" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
              <span class="absolute right-[10px] text-sm font-bold top-[30px]"> FCFA</span>
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <label for="" class="text-sm font-bold">Appliquer une réduction</label>
              <input type="text" v-model.number="reduction" class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
            </div>
            <div v-if="prix && reduction" class="w-full flex flex-col relative my-[10px]">
              <label class="text-sm font-bold">Pourcentage de réduction</label> 
              <span class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">{{ pourcentageReduction }}%</span>
            </div>
            <div v-if="prix && reduction" class="w-full flex flex-col relative my-[10px]">
              <label class="text-sm font-bold">Montant restant</label>
              <span class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">{{ montantRestant }} FCFA</span>
            </div>
            <div class="w-full flex flex-col relative my-[10px]">
              <button type="submit" class="p-[10px] px-[20px] text-sm w-full flex justify-center items-center h-[45px] font-bold bg-gray-800 text-white rounded-[5px]">
                <span v-if="!isLoading ">Ajouter</span>
                <div v-if="isLoading" class="spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script setup>
import HeaderComponent from "../components/HeaderComponent.vue"
import axios from 'axios'
import { ref, computed, onMounted } from 'vue'
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();
const user = computed(() => authStore.user);
const isLoading = ref(false)

const showBoxId = ref(null);
function toggleBox(id) {
  showBoxId.value = showBoxId.value === id ? null : id;
}
const showSupId = ref(null);
function toggleSupBox(id) {
  showSupId.value = showSupId.value === id ? null : id;
}

const useLot = ref(true)
const creerLot = ref(false)
const ModifieuseLot = ref(true)
const ModifiecreerLot = ref(false)
const nomuseLot = ref('')
const ModifienomuseLot = ref('')
const suggestionsLot = ref([])
const ModifienomLot = ref('')
const isfiltre = ref(false)

function toggleFiltre() {
  isfiltre.value = !isfiltre.value
}
function toggleuseLot() {
  creerLot.value = false
  useLot.value = true
}
function togglecreerLot() {
  useLot.value = false
  creerLot.value = true
}
function ModifietoggleuseLot() {
  ModifiecreerLot.value = !ModifiecreerLot.value
  ModifieuseLot.value = !ModifieuseLot.value
}
function ModifietogglecreerLot() {
  ModifieuseLot.value = !ModifieuseLot.value
  ModifiecreerLot.value = !ModifiecreerLot.value
}

const activeLotIndex = ref(null);

const toggleLot = (index) => {
  activeLotIndex.value = activeLotIndex.value === index ? null : index;
};

const lots = ref([])
const imei1 = ref('')
const imei2 = ref('')
const etat = ref('')
const produits = ref([])
const errorMessage = ref('')
const successMessage = ref('')
const nom = ref('');
const nomLot = ref('')
const categori = ref('');
const quantite = ref(1);
const description = ref('');
const stock = ref('');
const prix_reduit = ref(0);
const seuil = ref(null)
const Modifienom = ref('');
const Modifiecategori = ref('');
const Modifiequantite = ref('');
const Modifiedescription = ref('');
const Modifiestock = ref('');
const Modifieprix_reduit = ref(0);
const Modifieprix = ref(0);
const modifieArticle = ref(false)
const ModifieId = ref('')
const Modifieseuil = ref(0)
const Modifieimei1 = ref('')
const Modifieimei2 = ref('')
const Modifieetat = ref('')
const isLoading1 = ref(false)

const newCapacity = ref('');
const capacities = ref([]);
const newColor = ref('');
const colors = ref([]);

function addCapacity() {
  if (newCapacity.value) {
    capacities.value.push(newCapacity.value);
    newCapacity.value = '';
  }
}

function removeCapacity(index) {
  capacities.value.splice(index, 1);
}

function addColor() {
  if (newColor.value) {
    colors.value.push(newColor.value);
    newColor.value = '';
  }
}

function removeColor(index) {
  colors.value.splice(index, 1);
}

const ModifienewCapacity = ref('');
const Modifiecapacities = ref([]);
const ModifienewColor = ref('');
const Modifiecolors = ref([]);

function ModifieaddCapacity() {
  if (ModifienewCapacity.value) {
    Modifiecapacities.value.push(ModifienewCapacity.value);
    ModifienewCapacity.value = '';
  }
}

function ModifieremoveCapacity(index) {
  Modifiecapacities.value.splice(index, 1);
}

function ModifieaddColor() {
  if (ModifienewColor.value) {
    Modifiecolors.value.push(ModifienewColor.value);
    ModifienewColor.value = '';
  }
}

function ModifieremoveColor(index) {
  Modifiecolors.value.splice(index, 1);
}

const filteredLot = ref([]);
const selectedLotId = ref(null);
const selectedLotCategori = ref('');
const searchQuery = ref(''); // Texte de recherche
const selectedStock = ref(''); // Stock sélectionné

// Fetch des lots depuis l'API
const fetchLots = async () => {
  isLoading1.value = true;
  try {
    const response = await axios.get('/api/lots');
    lots.value = response.data;
    
    // Appliquer le filtre initial basé sur le rôle de l'utilisateur
    applyStockFilter();
  } catch (error) {
    errorMessage.value = 'Erreur lors du chargement des produits.';
    console.error(error);
  } finally {
    isLoading1.value = false;
  }
};

// Appliquer le filtre par stock
const applyStockFilter = () => {
  const stockToFilter = selectedStock.value || (user.value.boutique === 'Cotonou' ? 'Stock Cotonou' : 'Stock Parakou');

  if (user.value.isSuper && selectedStock.value === '') {
    filteredLot.value = lots.value; // Montrer tous les lots si aucun stock n'est sélectionné et si isSuper
  } else {
    filteredLot.value = lots.value.map(lot => {
      const filteredProduits = lot.produits.filter(produit => produit.stock === stockToFilter);
      return { ...lot, produits: filteredProduits.length > 0 ? filteredProduits : [] };
    }).filter(lot => lot.produits.length > 0); // Exclure les lots sans produits
  }
};

// Appliquer la recherche de produit
const searchProduit = () => {
  const query = searchQuery.value.toLowerCase();

  applyStockFilter(); // Appliquer le filtre de stock d'abord

  // Effectuer la recherche
  filteredLot.value = filteredLot.value
    .map(lot => {
      const filteredProduits = lot.produits.filter(produit => {
        const matchesCapacities = produit.capacities.some(capacity => capacity.toLowerCase().includes(query));
        const matchesImei1 = produit.imei1?.toLowerCase().includes(query);
        const matchesImei2 = produit.imei2?.toLowerCase().includes(query);

        return matchesCapacities || matchesImei1 || matchesImei2;
      });

      const matchesLotName = lot.name.toLowerCase().includes(query);

      return {
        ...lot,
        produits: matchesLotName ? lot.produits : filteredProduits
      };
    })
    .filter(lot => lot.produits.length > 0 || lot.name.toLowerCase().includes(query));

  // Réappliquer le tri pour prioriser les lots avec produits invalides après la recherche
  sortLotsByInvalidProduits();
};

// Tri des lots avec isValide à false après chaque transformation
const sortLotsByInvalidProduits = () => {
  filteredLot.value = filteredLot.value.sort((a, b) => {
    const hasInvalidProduitA = a.produits.some(produit => !produit.isValide);
    const hasInvalidProduitB = b.produits.some(produit => !produit.isValide);

    if (hasInvalidProduitA && !hasInvalidProduitB) return -1; // A vient avant B
    if (!hasInvalidProduitA && hasInvalidProduitB) return 1;  // B vient avant A

    return 0; // Pas de changement si les deux lots ont les mêmes conditions
  });
};

// Appeler cette fonction au chargement des lots
fetchLots().then(() => {
  sortLotsByInvalidProduits(); // Tri initial après chargement des lots
});


// Gestion du filtre par stock
const filterByStock = (stock) => {
  selectedStock.value = stock; // Définir le stock sélectionné
  applyStockFilter(); // Filtrer par stock
};

// Réinitialisation du filtre de stock
const clearStockFilter = () => {
  selectedStock.value = ''; // Vider le filtre de stock
  applyStockFilter(); // Réinitialiser la recherche
};

const suggestions = (inputName) => {
    const input = nomuseLot.value;
    
    // Filtrer les lots par nom, par catégorie et exclure ceux qui contiennent "Occasion"
    const suggestions = lots.value.filter(p => 
        p.name.toLowerCase().includes(input.toLowerCase()) && 
        categories.value.some(categorie => categorie.name === p.categorie) &&
        !p.name.toLowerCase().includes('occasion')
    );
    
    if (inputName === 'nomuseLot') {
        suggestionsLot.value = suggestions;
    }
};

const invalidCount = (lot) => {
  return lot.produits.filter(produit => !produit.isValide).length;
};


const selectLot = (lot) => {
  nomuseLot.value = lot.name;
  selectedLotId.value = lot._id;
  selectedLotCategori.value = lot.categorie 
  suggestionsLot.value = [];
};

const deleteProduit = async (lotId, id) => {
  try {
    await axios.delete(`/api/lots/${lotId}/produits/${id}`);
    showBoxId.value = null
    showSupId.value = null
    fetchLots()
  } catch (error) {
    errorMessage.value = 'Erreur lors du changement de statut.';
    hideErrorMessageAfterDelay()
  }
};
const deleteLot = async (id) => {
  try {
    await axios.delete(`/api/lots/${id}`);
    modifieArticle.value = false
    ajouteArticle.value = false
    fetchLots()
  } catch (error) {
    errorMessage.value = 'Erreur lors la suppression.';
    hideErrorMessageAfterDelay()
  }
};
const selectedImages = ref([]);

const fileInput = ref(null);

const ajouteArticle = ref(false)

function ajouterArticle() {
    ajouteArticle.value = true
    modifieArticle.value = false
}
function fermerAjout() {
    ajouteArticle.value = false
}

const triggerFileInput = () => {
  fileInput.value.click();
};

const handleFileChange = (event) => {
  const files = event.target.files;
  for (let i = 0; i < files.length; i++) {
    const reader = new FileReader();
    reader.onload = (e) => {
      selectedImages.value.push(e.target.result);
    };
    reader.readAsDataURL(files[i]);
  }
};

const removeImage = (index) => {
  selectedImages.value.splice(index, 1);
};

const prix = ref(null);
const reduction = ref(null);
const Modifiereduction = ref(null);

const pourcentageReduction = computed(() => {
  if (prix.value && reduction.value) {
    return ((reduction.value / prix.value) * 100).toFixed(2);
  }
  return 0;
});

const ModifiepourcentageReduction = computed(() => {
  if (Modifieprix.value && Modifiereduction.value) {
    return ((Modifiereduction.value / Modifieprix.value) * 100).toFixed(2);
  }
  return 0;
});

const ModifiemontantRestant = computed(() => {
  if (Modifieprix.value && Modifiereduction.value) {
    return (Modifieprix.value - Modifiereduction.value).toFixed(2);
  }
  return Modifieprix.value;
});

const montantRestant = computed(() => {
  if (prix.value && reduction.value) {
    return (prix.value - reduction.value).toFixed(2);
  }
  return prix.value;
});

const submitForm = async () => {
  if (montantRestant.value != 0) {
    prix_reduit.value = Number(montantRestant.value); // Convertir en nombre
  }
  if (montantRestant.value == 0) {
    prix_reduit.value = Number(prix.value); // Convertir en nombre
  }
  
  try {
    isLoading.value = true
    const response = await axios.put(`/api/lots/${selectedLotId.value}/produit`, {
      quantite: quantite.value,
      prix: prix.value,
      stock: stock.value,
      imei1: imei1.value,
      imei2: imei2.value,
      prix_reduit: prix_reduit.value,
      capacities: capacities.value,
      colors: colors.value
    });
    // Réinitialiser les champs après la soumission
    quantite.value = '';
    stock.value = '';
    prix.value = '';
    prix_reduit.value = '';
    ajouteArticle.value = false;
    imei1.value = '';
    imei2.value = '';
    capacities.value = [];
    colors.value = [];
    fetchLots();
    successMessage.value = "Le produit a été ajouté avec succès"
    hideSuccessMessageAfterDelay()
  } catch (error) {
    errorMessage.value = `Erreur lors de l'ajout du produit`;
    hideErrorMessageAfterDelay()
  } finally {
    isLoading.value = false
  }
};

const createLot = async () => {
  const formData = new FormData();
  formData.append('name', nomLot.value);
  formData.append('categorie', categori.value);
  formData.append('description', description.value);
  formData.append('seuil', seuil.value);

  for (const file of fileInput.value.files) {
    formData.append('photos', file);
  }
  isLoading.value = true
  try {
    const response = await axios.post('/api/lots', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    nomLot.value = '';
    categori.value = '';
    description.value = '';
    seuil.value = '';
    selectedImages.value = [];
    togglecreerLot()
    fetchLots()
    successMessage.value = "Lot créé avec succès"
    hideSuccessMessageAfterDelay()
  } catch (error) {
    errorMessage.value = 'Erreur lors de l\'ajout du lot';
    hideErrorMessageAfterDelay()
  } finally {
    isLoading.value = false
  }
};

const ModifieLotE = async () => {
  const formData = new FormData();
  formData.append('name', nomLot.value);
  formData.append('categorie', categori.value);
  formData.append('description', description.value);

  for (const file of fileInput.value.files) {
    formData.append('photos', file);
  }

  try {
    const response = await axios.post('/api/lots', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    nomLot.value = '';
    categori.value = '';
    description.value = '';
    selectedImages.value = [];
    togglecreerLot()
    fetchLots()
  } catch (error) {
    errorMessage.value = 'Erreur lors de l\'ajout du produit';
    hideErrorMessageAfterDelay()
  }
};



onMounted(() => {
    fetchLots()
});

const categories = ref([])

const setCategories = (poste) => {
  const allCategories = {
    'Manager': [
      {
          'name': 'Apple',
          'icone': 'M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25', 
      }, 
      {
          'name': 'Samsung',
          'icone': 'm19.817 10.28l.046 2.694h-.023l-.78-2.693h-1.283v3.392h.848l-.046-2.785h.023l.836 2.785h1.227v-3.392zm-16.15 0l-.641 3.428h.928l.47-3.118h.023l.459 3.118h.916l-.63-3.427zm5.181 0l-.424 2.614h-.023l-.424-2.613H6.58l-.069 3.427h.86l.023-3.083h.011l.573 3.083h.871l.573-3.083h.023l.023 3.083h.86l-.08-3.427zm-7.266 2.454a.5.5 0 0 1 .011.252c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332H0v.264c0 .768.607.997 1.25.997c.618 0 1.134-.218 1.214-.78c.046-.298.012-.492 0-.561c-.16-.722-1.467-.929-1.559-1.33a.5.5 0 0 1 0-.183c.023-.115.104-.23.31-.23s.32.127.32.31v.206h.86v-.24c0-.745-.676-.86-1.157-.86c-.608 0-1.112.206-1.204.757a1.04 1.04 0 0 0 .012.458c.137.71 1.364.917 1.536 1.352m11.152 0c.034.08.022.184.011.253c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332h-.917v.264c0 .756.596.985 1.238.985c.619 0 1.123-.206 1.203-.779c.046-.298.012-.481 0-.562c-.137-.71-1.433-.928-1.524-1.318a.5.5 0 0 1 0-.183c.023-.115.103-.23.31-.23c.194 0 .32.127.32.31v.206h.848v-.24c0-.745-.665-.86-1.146-.86c-.607 0-1.1.195-1.192.757c-.023.149-.023.286.012.458c.137.71 1.34.905 1.513 1.352m2.888.459c.24 0 .31-.16.332-.252c.012-.035.012-.092.012-.126V10.28h.87v2.464c0 .069 0 .195-.01.23c-.058.641-.562.847-1.193.847c-.63 0-1.134-.206-1.192-.848c0-.034-.011-.16-.011-.229V10.28h.87v2.533c0 .046 0 .091.012.126c0 .091.07.252.31.252m7.152-.034c.252 0 .332-.16.355-.253c.011-.034.011-.091.011-.126v-.493h-.355v-.504H24v.917c0 .069 0 .115-.011.23c-.058.63-.597.847-1.204.847s-1.146-.217-1.203-.848c-.012-.114-.012-.16-.012-.229v-1.444c0-.057.012-.172.012-.23c.08-.641.596-.847 1.203-.847s1.135.206 1.203.848c.012.103.012.229.012.229v.115h-.86v-.195s0-.08-.011-.126c-.012-.08-.08-.252-.344-.252c-.252 0-.32.16-.344.252c-.011.045-.011.103-.011.16v1.57c0 .046 0 .092.011.126c0 .092.092.253.333.253', 
      }, 
      {
          'name': 'Accessoires',
          'icone': 'M1.5 8.5V13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8.5M8 8.5v5M1.5 10H8M.5 4L2 .5h10L13.5 4H.5zm4.25 0v1a2 2 0 0 1-2 2h-.28a2 2 0 0 1-2-2V4m8.78 0v1a2 2 0 0 1-2 2h-.5a2 2 0 0 1-2-2V4m8.75 0v1a2 2 0 0 1-2 2h-.25a2 2 0 0 1-2-2V4'
      }, 
    ],
    'Vendeur(se) Accessoires et Téléphone': [
      {
          'name': 'Apple',
          'icone': 'M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25', 
      }, 
      {
          'name': 'Samsung',
          'icone': 'm19.817 10.28l.046 2.694h-.023l-.78-2.693h-1.283v3.392h.848l-.046-2.785h.023l.836 2.785h1.227v-3.392zm-16.15 0l-.641 3.428h.928l.47-3.118h.023l.459 3.118h.916l-.63-3.427zm5.181 0l-.424 2.614h-.023l-.424-2.613H6.58l-.069 3.427h.86l.023-3.083h.011l.573 3.083h.871l.573-3.083h.023l.023 3.083h.86l-.08-3.427zm-7.266 2.454a.5.5 0 0 1 .011.252c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332H0v.264c0 .768.607.997 1.25.997c.618 0 1.134-.218 1.214-.78c.046-.298.012-.492 0-.561c-.16-.722-1.467-.929-1.559-1.33a.5.5 0 0 1 0-.183c.023-.115.104-.23.31-.23s.32.127.32.31v.206h.86v-.24c0-.745-.676-.86-1.157-.86c-.608 0-1.112.206-1.204.757a1.04 1.04 0 0 0 .012.458c.137.71 1.364.917 1.536 1.352m11.152 0c.034.08.022.184.011.253c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332h-.917v.264c0 .756.596.985 1.238.985c.619 0 1.123-.206 1.203-.779c.046-.298.012-.481 0-.562c-.137-.71-1.433-.928-1.524-1.318a.5.5 0 0 1 0-.183c.023-.115.103-.23.31-.23c.194 0 .32.127.32.31v.206h.848v-.24c0-.745-.665-.86-1.146-.86c-.607 0-1.1.195-1.192.757c-.023.149-.023.286.012.458c.137.71 1.34.905 1.513 1.352m2.888.459c.24 0 .31-.16.332-.252c.012-.035.012-.092.012-.126V10.28h.87v2.464c0 .069 0 .195-.01.23c-.058.641-.562.847-1.193.847c-.63 0-1.134-.206-1.192-.848c0-.034-.011-.16-.011-.229V10.28h.87v2.533c0 .046 0 .091.012.126c0 .091.07.252.31.252m7.152-.034c.252 0 .332-.16.355-.253c.011-.034.011-.091.011-.126v-.493h-.355v-.504H24v.917c0 .069 0 .115-.011.23c-.058.63-.597.847-1.204.847s-1.146-.217-1.203-.848c-.012-.114-.012-.16-.012-.229v-1.444c0-.057.012-.172.012-.23c.08-.641.596-.847 1.203-.847s1.135.206 1.203.848c.012.103.012.229.012.229v.115h-.86v-.195s0-.08-.011-.126c-.012-.08-.08-.252-.344-.252c-.252 0-.32.16-.344.252c-.011.045-.011.103-.011.16v1.57c0 .046 0 .092.011.126c0 .092.092.253.333.253', 
      }, 
      {
          'name': 'Accessoires',
          'icone': 'M1.5 8.5V13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8.5M8 8.5v5M1.5 10H8M.5 4L2 .5h10L13.5 4H.5zm4.25 0v1a2 2 0 0 1-2 2h-.28a2 2 0 0 1-2-2V4m8.78 0v1a2 2 0 0 1-2 2h-.5a2 2 0 0 1-2-2V4m8.75 0v1a2 2 0 0 1-2 2h-.25a2 2 0 0 1-2-2V4'
      }, 
    ],
    'Vendeur(se) Téléphone': [
    {
          'name': 'Apple',
          'icone': 'M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25', 
      }, 
      {
          'name': 'Samsung',
          'icone': 'm19.817 10.28l.046 2.694h-.023l-.78-2.693h-1.283v3.392h.848l-.046-2.785h.023l.836 2.785h1.227v-3.392zm-16.15 0l-.641 3.428h.928l.47-3.118h.023l.459 3.118h.916l-.63-3.427zm5.181 0l-.424 2.614h-.023l-.424-2.613H6.58l-.069 3.427h.86l.023-3.083h.011l.573 3.083h.871l.573-3.083h.023l.023 3.083h.86l-.08-3.427zm-7.266 2.454a.5.5 0 0 1 .011.252c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332H0v.264c0 .768.607.997 1.25.997c.618 0 1.134-.218 1.214-.78c.046-.298.012-.492 0-.561c-.16-.722-1.467-.929-1.559-1.33a.5.5 0 0 1 0-.183c.023-.115.104-.23.31-.23s.32.127.32.31v.206h.86v-.24c0-.745-.676-.86-1.157-.86c-.608 0-1.112.206-1.204.757a1.04 1.04 0 0 0 .012.458c.137.71 1.364.917 1.536 1.352m11.152 0c.034.08.022.184.011.253c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332h-.917v.264c0 .756.596.985 1.238.985c.619 0 1.123-.206 1.203-.779c.046-.298.012-.481 0-.562c-.137-.71-1.433-.928-1.524-1.318a.5.5 0 0 1 0-.183c.023-.115.103-.23.31-.23c.194 0 .32.127.32.31v.206h.848v-.24c0-.745-.665-.86-1.146-.86c-.607 0-1.1.195-1.192.757c-.023.149-.023.286.012.458c.137.71 1.34.905 1.513 1.352m2.888.459c.24 0 .31-.16.332-.252c.012-.035.012-.092.012-.126V10.28h.87v2.464c0 .069 0 .195-.01.23c-.058.641-.562.847-1.193.847c-.63 0-1.134-.206-1.192-.848c0-.034-.011-.16-.011-.229V10.28h.87v2.533c0 .046 0 .091.012.126c0 .091.07.252.31.252m7.152-.034c.252 0 .332-.16.355-.253c.011-.034.011-.091.011-.126v-.493h-.355v-.504H24v.917c0 .069 0 .115-.011.23c-.058.63-.597.847-1.204.847s-1.146-.217-1.203-.848c-.012-.114-.012-.16-.012-.229v-1.444c0-.057.012-.172.012-.23c.08-.641.596-.847 1.203-.847s1.135.206 1.203.848c.012.103.012.229.012.229v.115h-.86v-.195s0-.08-.011-.126c-.012-.08-.08-.252-.344-.252c-.252 0-.32.16-.344.252c-.011.045-.011.103-.011.16v1.57c0 .046 0 .092.011.126c0 .092.092.253.333.253', 
      },
      {
          'name': 'Accessoires',
          'icone': 'M1.5 8.5V13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8.5M8 8.5v5M1.5 10H8M.5 4L2 .5h10L13.5 4H.5zm4.25 0v1a2 2 0 0 1-2 2h-.28a2 2 0 0 1-2-2V4m8.78 0v1a2 2 0 0 1-2 2h-.5a2 2 0 0 1-2-2V4m8.75 0v1a2 2 0 0 1-2 2h-.25a2 2 0 0 1-2-2V4'
      }, 
    ],
    'Vendeur(se) Accessoires': [
    {
          'name': 'Apple',
          'icone': 'M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25', 
      }, 
      {
          'name': 'Samsung',
          'icone': 'm19.817 10.28l.046 2.694h-.023l-.78-2.693h-1.283v3.392h.848l-.046-2.785h.023l.836 2.785h1.227v-3.392zm-16.15 0l-.641 3.428h.928l.47-3.118h.023l.459 3.118h.916l-.63-3.427zm5.181 0l-.424 2.614h-.023l-.424-2.613H6.58l-.069 3.427h.86l.023-3.083h.011l.573 3.083h.871l.573-3.083h.023l.023 3.083h.86l-.08-3.427zm-7.266 2.454a.5.5 0 0 1 .011.252c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332H0v.264c0 .768.607.997 1.25.997c.618 0 1.134-.218 1.214-.78c.046-.298.012-.492 0-.561c-.16-.722-1.467-.929-1.559-1.33a.5.5 0 0 1 0-.183c.023-.115.104-.23.31-.23s.32.127.32.31v.206h.86v-.24c0-.745-.676-.86-1.157-.86c-.608 0-1.112.206-1.204.757a1.04 1.04 0 0 0 .012.458c.137.71 1.364.917 1.536 1.352m11.152 0c.034.08.022.184.011.253c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332h-.917v.264c0 .756.596.985 1.238.985c.619 0 1.123-.206 1.203-.779c.046-.298.012-.481 0-.562c-.137-.71-1.433-.928-1.524-1.318a.5.5 0 0 1 0-.183c.023-.115.103-.23.31-.23c.194 0 .32.127.32.31v.206h.848v-.24c0-.745-.665-.86-1.146-.86c-.607 0-1.1.195-1.192.757c-.023.149-.023.286.012.458c.137.71 1.34.905 1.513 1.352m2.888.459c.24 0 .31-.16.332-.252c.012-.035.012-.092.012-.126V10.28h.87v2.464c0 .069 0 .195-.01.23c-.058.641-.562.847-1.193.847c-.63 0-1.134-.206-1.192-.848c0-.034-.011-.16-.011-.229V10.28h.87v2.533c0 .046 0 .091.012.126c0 .091.07.252.31.252m7.152-.034c.252 0 .332-.16.355-.253c.011-.034.011-.091.011-.126v-.493h-.355v-.504H24v.917c0 .069 0 .115-.011.23c-.058.63-.597.847-1.204.847s-1.146-.217-1.203-.848c-.012-.114-.012-.16-.012-.229v-1.444c0-.057.012-.172.012-.23c.08-.641.596-.847 1.203-.847s1.135.206 1.203.848c.012.103.012.229.012.229v.115h-.86v-.195s0-.08-.011-.126c-.012-.08-.08-.252-.344-.252c-.252 0-.32.16-.344.252c-.011.045-.011.103-.011.16v1.57c0 .046 0 .092.011.126c0 .092.092.253.333.253', 
      }, 
      {
          'name': 'Accessoires',
          'icone': 'M1.5 8.5V13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8.5M8 8.5v5M1.5 10H8M.5 4L2 .5h10L13.5 4H.5zm4.25 0v1a2 2 0 0 1-2 2h-.28a2 2 0 0 1-2-2V4m8.78 0v1a2 2 0 0 1-2 2h-.5a2 2 0 0 1-2-2V4m8.75 0v1a2 2 0 0 1-2 2h-.25a2 2 0 0 1-2-2V4'
      },
    ]
  };
  categories.value = allCategories[poste] || [];
};

const selectedCategory = ref(null);

const currentPage = ref(1);
const itemsPerPage = ref(10);
const totalPages = computed(() => Math.ceil(filteredLot.value.length / itemsPerPage.value));

const paginatedLots = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return filteredLot.value.slice(start, end);
});

const goToPage = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= totalPages.value) {
    currentPage.value = pageNumber;
  }
};

const ModifielotId = ref('')
const ModifieLotCategorie = ref('')

const ouvrirModification = (lot, produit) => {
  modifieArticle.value = true;
  ajouteArticle.value = false;
  ModifiecreerLot.value = false;
  ModifieuseLot.value = true
  lot.value = { ...lot };
  produit.value = { ...produit };
  Modifiequantite.value = produit.quantite;
  Modifiestock.value = produit.stock;
  Modifieprix.value = produit.prix;
  ModifielotId.value = lot._id;
  ModifieLotCategorie.value = lot.categorie
  ModifieId.value = produit._id;
  Modifieseuil.value = produit.seuil;
  Modifieetat.value = produit.etat;
  Modifieimei1.value = produit.imei1;
  Modifieimei2.value = produit.imei2;
  Modifiecapacities.value = produit.capacities;
  Modifiecolors.value = produit.colors;
};
const ouvrirModificationLot = (lot) => {
  modifieArticle.value = true;
  ajouteArticle.value = false;
  ModifiecreerLot.value = true;
  ModifieuseLot.value = false
  lot.value = { ...lot };
  ModifielotId.value = lot._id;
  ModifieLotCategorie.value = lot.categorie
  ModifienomLot.value = lot.name;
  Modifiedescription.value = lot.description;
  selectedImages.value = lot.photos
  Modifieseuil.value = lot.seuil

};

const fermerModification = () => {
  modifieArticle.value = false
};

const submitModification = async () => {
  if (ModifiemontantRestant.value != 0) {
    Modifieprix_reduit.value = Number(ModifiemontantRestant.value);
  }
  if (ModifiemontantRestant.value == 0) {
    Modifieprix_reduit.value = Modifieprix.value;
  }

  isLoading.value = true
  try {
    await axios.put(`/api/lots/${ModifielotId.value}/produit/${ModifieId.value}/`, {
      quantite: Modifiequantite.value,
      prix: Modifieprix.value,
      stock: Modifiestock.value,
      seuil: Modifieseuil.value,
      etat: Modifieetat.value,
      imei1: Modifieimei1.value,
      imei2: Modifieimei2.value,
      prix_reduit: Modifieprix_reduit.value,
      capacities: Modifiecapacities.value,
      colors: Modifiecolors.value
    });
    fetchLots();
    fermerModification();
    Modifiequantite.value = '';
    Modifiestock.value = '';
    Modifieprix.value = 0
    Modifieprix_reduit.value = 0;
    Modifieseuil.value = 0
    Modifieetat.value = '';
    Modifieimei1.value = '';
    Modifieimei2.value = ''
    Modifiecapacities.value = []
    Modifiecolors.value = []
    successMessage.value = "Produit modifié avec succès"
    hideSuccessMessageAfterDelay()
  } catch (error) {
    errorMessage.value = 'Erreur lors de la modification du produit';
    hideErrorMessageAfterDelay()
  } finally {
    isLoading.value = false
  }
};
const ModifieLot = async () => {
  const formData = new FormData();
  formData.append('name', ModifienomLot.value);
  formData.append('categorie', ModifieLotCategorie.value);
  formData.append('description', Modifiedescription.value);
  formData.append('seuil', Modifieseuil.value);

  // Ajouter les fichiers seulement s'il y en a de nouveaux
  if (fileInput.value.files.length > 0) {
    for (const file of fileInput.value.files) {
      formData.append('photos', file);
    }
  }

  isLoading.value = true;
  try {
    const response = await axios.put(`/api/lots/${ModifielotId.value}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    ModifienomLot.value = '';
    ModifieLotCategorie.value = '';
    Modifiedescription.value = '';
    Modifieseuil.value = '';
    selectedImages.value = [];
    fetchLots();
    modifieArticle.value = false;
    ModifiecreerLot.value = false;
    successMessage.value = "Lot modifié avec succès";
    hideSuccessMessageAfterDelay();
  } catch (error) {
    errorMessage.value = 'Erreur lors de la modification du lot';
    hideErrorMessageAfterDelay();
  } finally {
    isLoading.value = false;
  }
};
const toggleValide = async (Lotid, produitId) => {
  try {
    await axios.patch(`/api/lots/${Lotid}/produits/${produitId}/toggle-isValide`);
    const index = produits.value.findIndex((e) => e._id === produitId);
    if (index !== -1) {
      produits.value[index].isValide = !produits.value[index].isValide;
    }
    showBoxId.value = null;
    fetchLots();
  } catch (error) {
    errorMessage.value = 'Erreur lors du changement de statut isValide.';
    hideErrorMessageAfterDelay()
  }
}; 

const toggleOnline = async (Lotid, produitId) => {
  try {
    await axios.patch(`/api/lots/${Lotid}/produits/${produitId}/toggle-isOnline`);
    const index = produits.value.findIndex((e) => e._id === produitId);
    if (index !== -1) {
      produits.value[index].isOnline = !produits.value[index].isOnline;
    }
    showBoxId.value = null;
    fetchLots();
  } catch (error) {
    errorMessage.value = 'Erreur lors du changement de statut isOnline.';
    hideErrorMessageAfterDelay()
  }
};


const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 5000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 10000);
};


onMounted(() => {
  setCategories(user.value.poste)
})

</script>

<style scoped>
.shadow-4xl {
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
}
.shadow-blue-500 {
  box-shadow: 0 0px 15px rgba(59, 130, 246, 0.5);
}

#li_two:nth-child(white) {
    background-color: gray;
}
#li_two:nth-child(odd) {
    background-color: rgb(243 244 246);
}
.spinner22 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: rgb(234, 88, 12);
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
